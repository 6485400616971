import React from 'react';
import { Layout, Row, Col } from 'antd';
import Header from '../../components/PageLayout/Header';
import SidebarWrapper from '../../components/PageLayout/Sidebar';
import AboutTile from '../../components/AbouTile';
import Helmet from "react-helmet"


const Works = () => (
  <Layout className="outerPadding">
    <Layout className="container">

      <Header />
      <Helmet title="almikan | Works" />
      <SidebarWrapper>
        <div className="marginTopTitle">
          <h1 className="titleSeparate">Works</h1>
        </div>




        <Row gutter={[20, 20]}>
        <Col xs={24} sm={24} md={12} lg={8}>
            <AboutTile
              img="pokemonbot.png"
              alt="POKEMONZUKAN"
              textH4="Line Bot"
              textH3="My Pokemon Dictionary"
              link="https://github.com/jphacks/A_2111"
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <AboutTile
              img="3.png"
              alt="COCONOMASK"
              textH4="COCOa NOtitfication MASK"
              textH3="COCONOMASK"
              link="https://github.com/jphacks/A_2111"
            />
          </Col>

          <Col xs={24} sm={24} md={12} lg={8}>
            <AboutTile
              img="MAPLE.png"
              alt="MAPLE"
              textH4="MAP × Photo"
              textH3="MAPLE"
              link="https://github.com/Al-Mikan/Maple"
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <AboutTile
              img="Booktree.png"
              alt="BOOKTREE"
              textH4="Book × Conection"
              textH3="BOOKTREE"
              link="https://github.com/takapiro99/book-tree"
            />
          </Col>
        </Row>
      </SidebarWrapper>
    </Layout>
    <div className='bottomtxt'>
      <a className="bottomlink" href='https://privacypolicy.almikan.com/'>privacy policy</a>・Powered by <a className="bottomlink" href='https://github.com/rolwin100/rolwinreevan_gatsby_blog'>rolwinreevan gatsby blog theme</a> for Gatsby.
    </div>
  </Layout>
);

export default Works;